import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './css/stakes.css';

const HowItWorks = () => (
  <Container className='stakes-container text-center'>
    <Row>
      <Col xs={12}>
        <h1>How it works</h1>
        <video width='75%' height='auto' controls>
          <source src='/Videos/howitworks.mp4' type='video/mp4' />
        </video>
      </Col>
    </Row>
  </Container>
);

export default HowItWorks;
