import React from 'react';

import wk from '../assets/wk.svg';
import wq from '../assets/wq.svg';
import wr from '../assets/wr.svg';
import wb from '../assets/wb.svg';
import wn from '../assets/wn.svg';
import wp from '../assets/wp.svg';
import bk from '../assets/bk.svg';
import bq from '../assets/bq.svg';
import br from '../assets/br.svg';
import bb from '../assets/bb.svg';
import bn from '../assets/bn.svg';
import bp from '../assets/bp.svg';

export const pieceSet = {
  wK: ({ squareWidth, isDragging }) => (
    <img
      style={{
        width: squareWidth,
        height: squareWidth,
      }}
      src={wk}
      alt='white king'
    />
  ),
  wQ: ({ squareWidth, isDragging }) => (
    <img
      style={{
        width: squareWidth,
        height: squareWidth,
      }}
      src={wq}
      alt='white queen'
    />
  ),
  wR: ({ squareWidth, isDragging }) => (
    <img
      style={{
        width: squareWidth,
        height: squareWidth,
      }}
      src={wr}
      alt='white rook'
    />
  ),
  wN: ({ squareWidth, isDragging }) => (
    <img
      style={{
        width: squareWidth,
        height: squareWidth,
      }}
      src={wn}
      alt='white knight'
    />
  ),
  wB: ({ squareWidth, isDragging }) => (
    <img
      style={{
        width: squareWidth,
        height: squareWidth,
      }}
      src={wb}
      alt='white bishop'
    />
  ),
  wP: ({ squareWidth, isDragging }) => (
    <img
      style={{
        width: squareWidth,
        height: squareWidth,
      }}
      src={wp}
      alt='white pawn'
    />
  ),
  bK: ({ squareWidth, isDragging }) => (
    <img
      style={{
        width: squareWidth,
        height: squareWidth,
      }}
      src={bk}
      alt='black king'
    />
  ),
  bQ: ({ squareWidth, isDragging }) => (
    <img
      style={{
        width: squareWidth,
        height: squareWidth,
      }}
      src={bq}
      alt='black queen'
    />
  ),
  bR: ({ squareWidth, isDragging }) => (
    <img
      style={{
        width: squareWidth,
        height: squareWidth,
      }}
      src={br}
      alt='black rook'
    />
  ),
  bN: ({ squareWidth, isDragging }) => (
    <img
      style={{
        width: squareWidth,
        height: squareWidth,
      }}
      src={bn}
      alt='black knight'
    />
  ),
  bB: ({ squareWidth, isDragging }) => (
    <img
      style={{
        width: squareWidth,
        height: squareWidth,
      }}
      src={bb}
      alt='black bishop'
    />
  ),
  bP: ({ squareWidth, isDragging }) => (
    <img
      style={{
        width: squareWidth,
        height: squareWidth,
      }}
      src={bp}
      alt='black pawn'
    />
  ),
};
