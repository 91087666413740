import axios from 'axios';
import {
  GET_PROBLEMS_SUCCESS,
  GET_PROBLEMS_FAILURE,
  PROBLEM_COMPLETE_SUCCESS,
  PROBLEM_COMPLETE_FAILURE
} from "../actions/types";

export const problemCompleted = (problemId) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ problemId, date: new Date() });
  try {
    const res = await axios.post('/api/completedProblems', body, config)
    dispatch({
      type: PROBLEM_COMPLETE_SUCCESS,
      payload: res.data
    })
  } catch(err) {
    dispatch({
      type: PROBLEM_COMPLETE_FAILURE,
      payload: { msg: err.response, status: err.response.status }
    });
  }
}

export const getProblems = () => async dispatch => {
  try {
    const res = await axios.get('/api/completedProblems')
    dispatch({
      type: GET_PROBLEMS_SUCCESS,
      payload: res.data
    })
  } catch(err) {
    dispatch({
      type: GET_PROBLEMS_FAILURE,
      payload: { msg: err.response, status: err.response.status }
    });
  }
}