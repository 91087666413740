import {
  GET_MATE_IN_1,
  MATE_IN_1_SUCCESS,
  MATE_IN_1_FAILURE,
  GET_MATE_IN_2,
  MATE_IN_2_SUCCESS,
  MATE_IN_2_FAILURE,
  GET_MATE_IN_3,
  MATE_IN_3_SUCCESS,
  MATE_IN_3_FAILURE,
  GET_MATE_IN_3_TEST,
  MATE_IN_3_TEST_SUCCESS,
  MATE_IN_3_TEST_FAILURE,
  GET_MATE_IN_4,
  MATE_IN_4_SUCCESS,
  MATE_IN_4_FAILURE,
  GET_MATE_IN_MORE,
  MATE_IN_MORE_SUCCESS,
  MATE_IN_MORE_FAILURE,
  GET_FORKS,
  GET_FORKS_SUCCESS,
  GET_FORKS_FAILURE,
  GET_PINS,
  GET_PINS_SUCCESS,
  GET_PINS_FAILURE,
  GET_DISCOVERIES,
  GET_DISCOVERIES_SUCCESS,
  GET_DISCOVERIES_FAILURE,
  GET_SACRIFICES,
  GET_SACRIFICES_SUCCESS,
  GET_SACRIFICES_FAILURE,
  GET_EVALS,
  GET_EVALS_SUCCESS,
  GET_EVALS_FAILURE,
} from '../actions/types';

const initialState = {
  loading: true,
  mateIn1Problems: null,
  mateIn2Problems: null,
  mateIn3Problems: null,
  mateIn4Problems: null,
  mateInMoreProblems: null,
  mateIn3TestProblems: null,
  pinProblems: null,
  forkProblems: null,
  discoveryProblems: null,
  sacrificeProblems: null,
  evalProblems: null,
};

export default function reduce(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_MATE_IN_1:
      return {
        ...state,
        loading: true,
      };
    case MATE_IN_1_SUCCESS:
      return {
        ...state,
        mateIn1Problems: payload,
        loading: false,
      };
    case MATE_IN_1_FAILURE:
      return {
        ...state,
        mateIn1Problems: null,
        loading: false,
      };
    case GET_MATE_IN_2:
      return {
        ...state,
        loading: true,
      };
    case MATE_IN_2_SUCCESS:
      return {
        ...state,
        mateIn2Problems: payload,
        loading: false,
      };
    case MATE_IN_2_FAILURE:
      return {
        ...state,
        mateIn2Problems: null,
        loading: false,
      };
    case GET_MATE_IN_3:
      return {
        ...state,
        loading: true,
      };
    case MATE_IN_3_SUCCESS:
      return {
        ...state,
        mateIn3Problems: payload,
        loading: false,
      };
    case MATE_IN_3_FAILURE:
      return {
        ...state,
        mateIn13roblems: null,
        loading: false,
      };
    case GET_MATE_IN_4:
      return {
        ...state,
        loading: true,
      };
    case MATE_IN_4_SUCCESS:
      return {
        ...state,
        mateIn4Problems: payload,
        loading: false,
      };
    case MATE_IN_4_FAILURE:
      return {
        ...state,
        mateIn4Problems: null,
        loading: false,
      };
    case GET_MATE_IN_MORE:
      return {
        ...state,
        loading: true,
      };
    case MATE_IN_MORE_SUCCESS:
      return {
        ...state,
        mateInMoreProblems: payload,
        loading: false,
      };
    case MATE_IN_MORE_FAILURE:
      return {
        ...state,
        mateInMoreProblems: null,
        loading: false,
      };
    case GET_MATE_IN_3_TEST:
      return {
        ...state,
        loading: true,
      };
    case MATE_IN_3_TEST_SUCCESS:
      return {
        ...state,
        mateIn3TestProblems: payload,
        loading: false,
      };
    case MATE_IN_3_TEST_FAILURE:
      return {
        ...state,
        mateInMoreProblems: null,
        loading: false,
      };
    case GET_SACRIFICES:
    case GET_DISCOVERIES:
    case GET_PINS:
    case GET_FORKS:
    case GET_EVALS:
      return {
        ...state,
        loading: true,
      };
    case GET_SACRIFICES_SUCCESS:
      return {
        ...state,
        sacrificeProblems: payload,
        loading: false,
      };
    case GET_SACRIFICES_FAILURE:
      return {
        ...state,
        sacrificeProblems: null,
        loading: false,
      };
    case GET_DISCOVERIES_SUCCESS:
      return {
        ...state,
        discoveryProblems: payload,
        loading: false,
      };
    case GET_DISCOVERIES_FAILURE:
      return {
        ...state,
        discoveryProblems: null,
        loading: false,
      };
    case GET_PINS_SUCCESS:
      return {
        ...state,
        pinProblems: payload,
        loading: false,
      };
    case GET_PINS_FAILURE:
      return {
        ...state,
        pinProblems: null,
        loading: false,
      };
    case GET_FORKS_SUCCESS:
      return {
        ...state,
        forkProblems: payload,
        loading: false,
      };
    case GET_FORKS_FAILURE:
      return {
        ...state,
        forkProblems: null,
        loading: false,
      };
    case GET_EVALS_SUCCESS:
      return {
        ...state,
        evalProblems: payload,
        loading: false,
      };
    case GET_EVALS_FAILURE:
      return {
        ...state,
        evalProblems: null,
        loading: false,
      };
    default:
      return state;
  }
}
