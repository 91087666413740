import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// real table = prctbl_1OEcvcJS4pN9bEUf7RMlf8ht
// test table = prctbl_1OEzJUJS4pN9bEUfVnB5o4Zt
// real pk_live_51OC4v0JS4pN9bEUfOowrVBI3GNwMExSSg1FibIXx7gu4LdN5QJRgmVGsutyrrWnKYfiC330qXg5owtCmAbA6n32j00TbpUDkOw
// test pk_test_51OC4v0JS4pN9bEUfqE1cAI57gobWFleRORa1LV65B7JqzgDf54lF1Lv0sSx0GkgiNoxKYd7nCOJxsLzMo28SIVux00BOSLjYGS

const Premium = ({ userId }) => {
  if (userId) {
    return (
      <stripe-pricing-table
        pricing-table-id='prctbl_1OEcvcJS4pN9bEUf7RMlf8ht'
        publishable-key='pk_live_51OC4v0JS4pN9bEUfOowrVBI3GNwMExSSg1FibIXx7gu4LdN5QJRgmVGsutyrrWnKYfiC330qXg5owtCmAbA6n32j00TbpUDkOw'
        client-reference-id={userId}
      ></stripe-pricing-table>
    );
  } else {
    return <></>;
  }
};

Premium.propTypes = {
  userId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  userId: state?.auth?.user?._id,
});

export default connect(mapStateToProps, {})(Premium);
