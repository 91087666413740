import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './css/stakes.css';

const Stakes = () => (
  <Container className="stakes-container">
    <Row>
      <Col sm={5}>
        <div className="tactics-image" alt="chess tactic" />
      </Col>
      <Col sm={7}>
        <h2>Make the most of your chess study</h2>
        <h3 className="no-more">No More:</h3>
        <ul>
          <li className="stake-item">Hours spent on mindless training</li>
          <li className="stake-item">Months spent with no progress</li>
          <li className="stake-item">Seeing the same patterns over and over</li>
          <li className="stake-item">Frustrating or confusing problems</li>
          <li className="stake-item">Training tactics with no rating gains</li>
          <li className="stake-item">Computer playing soft lines</li>
        </ul>
      </Col>
    </Row>
  </Container>
);

export default Stakes;
