import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { login, clearAuthErrors } from '../../actions/auth.js';
import PropTypes from 'prop-types';

const Login = ({ login, clearAuthErrors, isAuthenticated, error }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    login(email, password);
  };

  if (isAuthenticated) {
    return <Redirect to='/dashboard' />;
  }

  const errorDiv =
    error && error.length > 0 ? (
      <div class='alert alert-danger' role='alert'>
        {error}
      </div>
    ) : (
      <></>
    );

  if (error && error.length > 0) {
    setTimeout(() => clearAuthErrors(), 3000);
  }

  return (
    <Container>
      <Row className='justify-content-center'>
        <Col xs={6} className='text-center'>
          <h1 className='large text-primary mt-5'>Log In</h1>
          <div>{errorDiv}</div>
          <form className='form' onSubmit={(e) => onSubmit(e)}>
            <div className='form-group'>
              <input
                type='email'
                placeholder='Email Address'
                name='email'
                value={email}
                size={40}
                onChange={(e) => onChange(e)}
              />
            </div>
            <div className='form-group'>
              <input
                type='password'
                placeholder='Password'
                name='password'
                value={password}
                size={40}
                onChange={(e) => onChange(e)}
              />
            </div>
            <p className='my-1'>
              <Link to='/forgot-password'>Forgot Password?</Link>
            </p>
            <input type='submit' className='btn btn-primary' value='Login' />
          </form>
          <p className='my-1'>
            Don't have an account? <Link to='/register'>Register</Link>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  clearAuthErrors: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  error: PropTypes.string,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.auth.errors?.errors[0]?.msg,
});

export default connect(mapStateToProps, { login, clearAuthErrors })(Login);
