import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProblemItem from '../../layouts/ProblemItem';
import { useHistory } from 'react-router-dom';
import { getMateIn4 } from '../../../actions/problems';
import { getProblems } from '../../../actions/completedProblems';
import { getProblemsToTrain } from '../helpers/problemHelpers';

const MateInFour = ({
  getMateIn4,
  problems: { mateIn4Problems, loading },
  getProblems,
  completedProblems,
  solveMode,
}) => {
  const history = useHistory();
  const [index, setCount] = useState(0);

  const updateIndex = () => {
    setCount(index + 1);
  };

  useEffect(() => {
    getMateIn4();
    getProblems();
  }, [getMateIn4, getProblems]);

  let problemsToSolve = null;
  if (mateIn4Problems && completedProblems) {
    problemsToSolve = getProblemsToTrain(
      mateIn4Problems,
      completedProblems,
      solveMode
    );
  }

  if (problemsToSolve && index >= problemsToSolve.length) {
    history.push('/');
    return <div></div>;
  }

  if (problemsToSolve && index >= problemsToSolve.length) {
    history.push('/');
    return <div></div>;
  }

  return (
    <React.Fragment>
      {loading ? (
        <div>...</div>
      ) : (
        <ProblemItem
          key={problemsToSolve[index].fen}
          index={index}
          fen={problemsToSolve[index].fen}
          lines={problemsToSolve[index].lines}
          problemId={problemsToSolve[index].puzzleId}
          onNext={updateIndex}
        />
      )}
    </React.Fragment>
  );
};

MateInFour.propTypes = {
  getMateIn4: PropTypes.func.isRequired,
  getProblems: PropTypes.func.isRequired,
  problems: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  problems: state.problems,
  completedProblems: state.completedProblems.completedProblems,
  solveMode: state.solve.solveMode,
});
export default connect(mapStateToProps, { getMateIn4, getProblems })(
  MateInFour
);
