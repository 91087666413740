import React from 'react';

const Help = () => {
  const style = {
    textAlign: 'center',
    paddingTop: '240px',
  };
  return <h2 style={style}>Please email help@chessgoals.com</h2>;
};

export default Help;
