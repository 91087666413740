import { SOLVE_MODE_REVIEW } from '../../../actions/types';

export function getProblemsToTrain(allProblems, completedProblems, solveMode) {
  let result = [];
  if (solveMode === SOLVE_MODE_REVIEW) {
    completedProblems.sort(compare).filter((completedProblem) =>
      allProblems.find((allProblem) => {
        const match = completedProblem.problemId === allProblem.puzzleId
        if (match) {
          result.push(allProblem)
        }
      })
    )
  } else {
    allProblems.forEach((problem) => {
      const match = completedProblems.find(
        (completedProblem) => completedProblem.problemId === problem.puzzleId
      );
      if (!match) {
        result.push(problem);
      }
    });
  }

  return result;
}

function compare(a, b) {
  if (a.date === null) return a
  if (b.date === null) return b
  return Date.parse(a.date) - Date.parse(b.date)
}
