import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from './Header';
import HowItWorks from './HowItWorks';
import Stakes from './Stakes';
import Values from './Values';
import Explain from './Explain';
import PropTypes from 'prop-types';

const Home = ({ isAuthenticated }) => {
  if (isAuthenticated) {
    return <Redirect to='/dashboard' />;
  }

  return (
    <div>
      <Header />
      <HowItWorks />
      <Stakes />
      <Values />
      <Explain />
    </div>
  );
};

Home.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {})(Home);
