import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';
import './css/primercard.css';
import { markSolveModeLearn, markSolveModeReview } from '../../actions/solve';

const ProblemCard = ({
  title,
  path,
  problems,
  completed,
  markSolveModeLearn,
  markSolveModeReview,
  isPremium,
}) => {
  const history = useHistory();

  let numCompleted = 0;
  let solvableProblems = problems;
  if (problems && !isPremium) {
    solvableProblems = solvableProblems.filter((problem) => problem.isPremium === false)
  }
  if (solvableProblems && completed) {
    completed.forEach((completed) => {
      const match = solvableProblems.find(
        (problem) => problem.puzzleId === completed.problemId
      );
      if (match) {
        numCompleted++;
      }
    });
  }

  const onClick = (isReviewing) => {
    if (isReviewing) {
      markSolveModeReview()
    } else {
      markSolveModeLearn()
    }
    history.push(path);
  };

  const onPremiumClick = () => {
    history.push('/premium')
  }
  
  const style = {
    height: isPremium ? "220px" : "275px",
  };

  return (
    <div className='primer-container text-center' style={style}>
      <h2 className='primer-title'>{title}</h2>
      {completed && solvableProblems && (
        <p>
          Completed {numCompleted} of {solvableProblems.length}
        </p>
      )}
      <div>
        {numCompleted > 0 && (
          <button
            type='button'
            className='btn btn-success'
            onClick={() => onClick(true)}
            id='review'
          >
            Review ({numCompleted})
          </button>
        )}
        {solvableProblems && numCompleted < solvableProblems.length && (
          <button
            type='button'
            className='btn btn-light'
            onClick={() => onClick(false)}
            id='solve'
          >
            Solve New
          </button>
        )}
      </div>
      {solvableProblems && !isPremium && (
          <button
          type='button'
          className='btn btn-light'
          onClick={() => onPremiumClick()}
          id='premium'
        >
          Unlock {problems.length - solvableProblems.length} Problems
        </button>
        )}
    </div>
  );
};

ProblemCard.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  problems: PropTypes.array,
  completed: PropTypes.array,
  markSolveModeLearn: PropTypes.func.isRequired,
  markSolveModeReview: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isPremium: state.auth.isPremium,
});

export default connect(mapStateToProps, {
  markSolveModeLearn,
  markSolveModeReview
})(ProblemCard);
