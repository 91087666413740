import Slider from '@mui/material/Slider';
import PropTypes from 'prop-types';
import { Container, Row, Button, Col } from 'react-bootstrap';
import Board from '../layouts/Board';
import { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { getEvals } from '../../actions/problems';
import { useHistory } from 'react-router-dom';
import { problemCompleted } from '../../actions/completedProblems';
import { getProblems } from '../../actions/completedProblems';
import { getProblemsToTrain } from '../Problems/helpers/problemHelpers';

const GuessTheEval = ({
  getEvals,
  evalProblems,
  loading,
  problemCompleted,
  getProblems,
  completedProblems,
  solveMode,
}) => {
  useEffect(() => {
    getEvals();
    getProblems();
  }, [getEvals, getProblems]);

  const history = useHistory();
  const [showAnswer, setShowAnswer] = useState(false);
  const [index, setCount] = useState(0);
  const [evalValue, setEvalValue] = useState(0);
  const [pawnValue, setPawnValue] = useState(0);
  const [kingValue, setKingValue] = useState(0);
  const [activityValue, setActivityValue] = useState(0);
  const [materialValue, setMaterialValue] = useState(0);
  const [spaceValue, setSpaceValue] = useState(0);

  const onSubmit = () => {
    setShowAnswer(true);
  };

  const onNext = async (problemId) => {
    if (showAnswer) {
      problemCompleted(problemId);
    } else {
      setCount(index + 1);
    }
    setShowAnswer(false);
    setEvalValue(0);
    setPawnValue(0);
    setKingValue(0);
    setActivityValue(0);
    setMaterialValue(0);
    setSpaceValue(0);
  };

  const handleChange = (event) => {
    switch (event.target.name) {
      case 'eval':
        setEvalValue(event.target.value);
        break;
      case 'pawn':
        setPawnValue(event.target.value);
        break;
      case 'king':
        setKingValue(event.target.value);
        break;
      case 'activity':
        setActivityValue(event.target.value);
        break;
      case 'material':
        setMaterialValue(event.target.value);
        break;
      case 'space':
        setSpaceValue(event.target.value);
        break;
    }
  };

  let problemsToSolve = null;
  if (completedProblems && evalProblems) {
    problemsToSolve = getProblemsToTrain(
      evalProblems,
      completedProblems,
      solveMode
    );
  }

  if (
    problemsToSolve &&
    problemsToSolve.length > 0 &&
    index >= problemsToSolve.length
  ) {
    history.push('/');
    return <div></div>;
  }

  const problem = problemsToSolve ? problemsToSolve[index] : null;

  return (
    <Fragment>
      {loading || !problem ? (
        <div>...</div>
      ) : (
        <Container>
          <Row className='justify-content-center'>
            <h2 className='center'>Guess The Eval</h2>
          </Row>
          <Row className='justify-content-center mt-5'>
            <Slider
              defaultValue={0}
              name='eval'
              value={evalValue}
              onChange={handleChange}
              min={-10}
              max={10}
              step={0.1}
              aria-label='Default'
              valueLabelDisplay='on'
              disabled={showAnswer}
            />
            <Slider
              defaultValue={0}
              value={showAnswer ? problem.eval : 0}
              min={-10}
              max={10}
              step={0.1}
              aria-label='Default'
              valueLabelDisplay={showAnswer ? 'on' : 'off'}
              disabled
            />
          </Row>
          <Row className='justify-content-center mt-2'>
            <Button
              variant='primary'
              size='sm'
              onClick={(e) => {
                onSubmit(problem.puzzleId);
              }}
            >
              Submit
            </Button>
            <Button
              className='ml-2'
              variant='primary'
              size='sm'
              onClick={(e) => {
                onNext(problem.puzzleId);
              }}
            >
              {showAnswer ? 'Next' : 'Skip'}
            </Button>
          </Row>
          <Row className='mt-4'>
            <Col lg={6} xs={12}>
              <Board
                key={problem.fen}
                fen={problem.fen}
                lines={problem.moves}
                defaultWidth={440}
              />
            </Col>
            <Col className='justify-content-center' lg={6} xs={12}>
              <p>Pawn Structure</p>
              <Slider
                defaultValue={0}
                name='pawn'
                value={pawnValue}
                onChange={handleChange}
                min={-10}
                max={10}
                step={0.1}
                aria-label='Default'
                valueLabelDisplay='on'
                disabled={showAnswer}
              />
              <Slider
                defaultValue={0}
                value={showAnswer ? problem.pawn : 0}
                min={-10}
                max={10}
                step={0.1}
                aria-label='Default'
                valueLabelDisplay={showAnswer ? 'on' : 'off'}
                disabled
              />
              <p>King safety</p>
              <Slider
                defaultValue={0}
                name='king'
                value={kingValue}
                onChange={handleChange}
                min={-10}
                max={10}
                step={0.1}
                aria-label='Default'
                valueLabelDisplay='on'
                disabled={showAnswer}
              />
              <Slider
                defaultValue={0}
                value={showAnswer ? problem.king : 0}
                min={-10}
                max={10}
                step={0.1}
                aria-label='Default'
                valueLabelDisplay={showAnswer ? 'on' : 'off'}
                disabled
              />
              <p>Piece Activity</p>
              <Slider
                defaultValue={0}
                name='activity'
                value={activityValue}
                onChange={handleChange}
                min={-10}
                max={10}
                step={0.1}
                aria-label='Default'
                valueLabelDisplay='on'
                disabled={showAnswer}
              />
              <Slider
                defaultValue={0}
                value={showAnswer ? problem.activity : 0}
                min={-10}
                max={10}
                step={0.1}
                aria-label='Default'
                valueLabelDisplay={showAnswer ? 'on' : 'off'}
                disabled
              />
              <p>Material</p>
              <Slider
                defaultValue={0}
                name='material'
                value={materialValue}
                onChange={handleChange}
                min={-10}
                max={10}
                step={0.1}
                aria-label='Default'
                valueLabelDisplay='on'
                disabled={showAnswer}
              />
              <Slider
                defaultValue={0}
                value={showAnswer ? problem.material : 0}
                min={-10}
                max={10}
                step={0.1}
                aria-label='Default'
                valueLabelDisplay={showAnswer ? 'on' : 'off'}
                disabled
              />
              <p>Space</p>
              <Slider
                defaultValue={0}
                name='space'
                value={spaceValue}
                onChange={handleChange}
                min={-10}
                max={10}
                step={0.1}
                aria-label='Default'
                valueLabelDisplay='on'
                disabled={showAnswer}
              />
              <Slider
                defaultValue={0}
                value={showAnswer ? problem.space : 0}
                min={-10}
                max={10}
                step={0.1}
                aria-label='Default'
                valueLabelDisplay={showAnswer ? 'on' : 'off'}
                disabled
              />
            </Col>
          </Row>
        </Container>
      )}
    </Fragment>
  );
};

GuessTheEval.propTypes = {
  getEvals: PropTypes.func.isRequired,
  problemCompleted: PropTypes.func.isRequired,
  getProblems: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  evalProblems: state.problems.evalProblems,
  loading: state.problems.loading,
  completedProblems: state.completedProblems.completedProblems,
  solveMode: state.solve.solveMode,
});

export default connect(mapStateToProps, {
  getEvals,
  problemCompleted,
  getProblems,
})(GuessTheEval);
