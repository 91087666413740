import {
  GET_PROBLEMS_SUCCESS,
  GET_PROBLEMS_FAILURE,
  PROBLEM_COMPLETE_SUCCESS,
  PROBLEM_COMPLETE_FAILURE,
} from '../actions/types';

const initialState = {
  completedProblems: [],
  error: null,
};

export default function reduce(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case PROBLEM_COMPLETE_SUCCESS:
    case GET_PROBLEMS_SUCCESS:
      return {
        ...state,
        completedProblems: payload,
        error: null,
      };
    case PROBLEM_COMPLETE_FAILURE:
    case GET_PROBLEMS_FAILURE:
      return {
        ...state,
        error: payload.msg,
      };
    default:
      return state;
  }
}
