import React from 'react';
import ChessBoard from 'chessboardjsx';
import Chess from 'chess.js';
import PropTypes from 'prop-types';
import { pieceSet } from './helpers/pieces';

import {
  moveResult,
  getFirstMove,
  getNextMove,
  getCompletedLine,
  removeNextAltMove,
  getCompletedAltCheckmateLine,
} from './helpers/moveHelper';

const style = {
  display: 'inline-block',
};

const highlightedStyle = {
  background: 'radial-gradient(circle, #1a688d 36%, transparent 10%)',
};

class Board extends React.Component {
  constructor(props) {
    super(props);

    this.initGame();
    this.orientation = props.fen.includes('w') ? 'black' : 'white';
    this.state = {
      fen: props.fen,
      lines: props.lines,
      moveNum: 0,
      highlights: {},
      clickedSquare: '',
    };
  }

  calcWidth = ({ screenWidth, screenHeight }) => {
    const smallest = this.props.defaultWidth ? this.props.defaultWidth : 560;
    const width = screenWidth * 0.8;
    return width > smallest ? smallest : width;
  };

  initGame() {
    this.game = new Chess(this.props.fen);
    setTimeout(() => {
      this.makeFirstMove();
    }, 500);
  }

  onSquareClick = (square) => {
    const highlights = this.state.highlights;
    if (square == this.state.clickedSquare) {
      this.setState({
        highlights: [],
        clickedSquare: '',
      });
    } else if (highlights && highlights[square] != null) {
      this.setState({
        highlights: [],
      });

      // make move
      const clickedSquare = this.state.clickedSquare;
      this.onDrop({ sourceSquare: clickedSquare, targetSquare: square });
    } else {
      // get list of possible moves for this square
      let moves = this.game.moves({
        square: square,
        verbose: true,
      });

      let squaresToHighlight = {};
      for (var i = 0; i < moves.length; i++) {
        squaresToHighlight[moves[i].to] = highlightedStyle;
      }

      this.setState({
        highlights: squaresToHighlight,
        clickedSquare: square,
      });
    }
  };

  makeFirstMove() {
    const nextMove = getFirstMove(this.state.lines);
    this.makeMove(nextMove);
  }

  makeMove(move) {
    this.game.move(move);

    this.setState({
      fen: this.game.fen(),
      moveNum: this.state.moveNum + 1,
    });
  }

  onDrop = ({ sourceSquare, targetSquare }) => {
    let move = this.game.move({
      from: sourceSquare,
      to: targetSquare,
      promotion: 'q',
    });

    if (move === null) return;

    const result = moveResult(this.game.history(), this.state.lines);
    if (result === 'correct') {
      const newMoveNum = this.state.moveNum + 1;
      this.setState(() => ({
        fen: this.game.fen(),
        moveNum: newMoveNum,
      }));
      if (this.props.rightAnswer) {
        this.props.rightAnswer();
      }

      const newLines = removeNextAltMove(
        this.game.history(),
        this.state.lines,
        newMoveNum
      );
      this.setState(() => ({
        lines: newLines,
      }));

      const completedLine = getCompletedLine(this.game.history(), newLines);

      if (completedLine == null) {
        this.makeNextMove();
      } else {
        this.resetBoard(completedLine);
      }
    } else if (result === 'incorrect') {
      if (this.game.in_checkmate()) {
        const altCompletedLine = getCompletedAltCheckmateLine(
          this.game.history(),
          this.state.lines
        );
        this.resetBoard(altCompletedLine);

        const newMoveNum = this.state.moveNum + 1;
        this.setState(() => ({
          fen: this.game.fen(),
          moveNum: newMoveNum,
        }));

        if (this.props.rightAnswer) {
          this.props.rightAnswer();
        }
      } else {
        if (this.props.wrongAnswer) {
          this.props.wrongAnswer();
        }
        this.game.undo();
      }
    } else {
      if (this.props.alternateAnswer) {
        this.props.alternateAnswer();
      }
      this.game.undo();
    }
  };

  resetBoard(completedLine) {
    if (this.props.lineCompleted) {
      this.props.lineCompleted();
    }
    const index = this.state.lines.indexOf(completedLine);

    const newLines = this.state.lines;
    newLines.splice(index, 1);

    if (newLines.length === 0) {
      return;
    } else {
      setTimeout(() => {
        this.initGame();
        this.setState(() => ({
          fen: this.game.fen(),
          moveNum: 0,
          lines: newLines,
        }));
      }, 500);
    }
  }

  makeNextMove() {
    const nextMove = getNextMove(
      this.game.history(),
      this.state.lines,
      this.state.moveNum
    );
    this.makeMove(nextMove);
  }

  render() {
    return (
      <div style={style}>
        <ChessBoard
          id={this.props.fen}
          pieces={pieceSet}
          position={this.state.fen}
          onDrop={this.onDrop}
          orientation={this.orientation}
          calcWidth={this.calcWidth}
          onSquareClick={this.onSquareClick}
          squareStyles={this.state.highlights}
        />
      </div>
    );
  }
}

Board.propTypes = {
  fen: PropTypes.string.isRequired,
  lines: PropTypes.array.isRequired,
  lineCompleted: PropTypes.func,
  wrongAnswer: PropTypes.func,
  rightAnswer: PropTypes.func,
  alternateAnswer: PropTypes.func,
  defaultWidth: PropTypes.number,
};

export default Board;
