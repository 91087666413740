import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './css/values.css';
const Values = () => (
  <div className="values-container">
    <Container>
      <Row>
        <Col sm>
          <h2>Learn</h2>
          <p>Ingrain tactical patterns into your brain.</p>
          <ul>
            <li className="value-item">Motif-based training</li>
            <li className="value-item">Play out multiple lines in each tactic.</li>
            <li className="value-item">Train tacticless positions</li>
          </ul>
        </Col>
        <Col sm>
          <h2>Train</h2>
          <p>Use our system to drill tactical concepts.</p>
          <ul>
            <li className="value-item">Hand-selected tactics and playable lines</li>
            <li className="value-item">Drill patterns</li>
            <li className="value-item">Build your instincts</li>
          </ul>
        </Col>
        <Col sm>
          <h2>Improve</h2>
          <p>Find tactics in your tournament and online chess games, not just in the tactics trainer.</p>
          <ul>
            <li className="value-item">Increase your rating</li>
            <li className="value-item">Find more tactics in your games</li>
            <li className="value-item">Win sharp positions</li>
          </ul>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Values;
