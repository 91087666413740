import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  register,
  clearAuthErrors,
  passwordMismatch,
} from '../../actions/auth.js';
import PropTypes from 'prop-types';

import { Container, Row, Col } from 'react-bootstrap';

const Register = ({
  register,
  clearAuthErrors,
  passwordMismatch,
  isAuthenticated,
  error,
  loginMessage,
}) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    password2: '',
    addToList: true,
  });

  const { name, email, password, password2, addToList } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onCheckboxChange = (e) =>
    setFormData({ ...formData, addToList: !addToList });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== password2) {
      passwordMismatch();
    } else {
      register({ name, email, password, addToList });
    }
  };

  if (isAuthenticated) {
    return <Redirect to='/dashboard' />;
  }

  const errorDiv =
    error && error.length > 0 ? (
      <div class='alert alert-danger' role='alert'>
        {error}
      </div>
    ) : (
      <></>
    );

  const messageDiv =
    loginMessage && loginMessage.length > 0 ? (
      <div class='alert alert-success' role='alert'>
        {loginMessage}
      </div>
    ) : (
      <></>
    );

  if (error && error.length > 0) {
    setTimeout(() => clearAuthErrors(), 3000);
  }

  return (
    <Container>
      <Row className='justify-content-center'>
        <Col lg={6} className='text-center background'>
          <h1 className='large text-primary mt-5'>Sign Up</h1>
          <p className='lead'>
            <i className='fas fa-user'></i> Create Your Account
          </p>
          {errorDiv}
          {messageDiv}
          <form className='form w-100' onSubmit={(e) => onSubmit(e)}>
            <div className='form-group'>
              <input
                type='text'
                placeholder='Username'
                name='name'
                value={name}
                size={40}
                onChange={(e) => onChange(e)}
              />
            </div>
            <div className='form-group w-100'>
              <input
                type='email'
                placeholder='Email Address'
                name='email'
                value={email}
                size={40}
                onChange={(e) => onChange(e)}
              />
            </div>
            <div className='form-group'>
              <input
                type='password'
                placeholder='Password'
                name='password'
                value={password}
                size={40}
                onChange={(e) => onChange(e)}
              />
            </div>
            <div className='form-group w-100'>
              <input
                type='password'
                placeholder='Confirm Password'
                value={password2}
                size={40}
                onChange={(e) => onChange(e)}
                name='password2'
              />
            </div>
            <div className='form-group'>
              <input
                type='checkbox'
                name='addToList'
                checked={addToList}
                onChange={(e) => onCheckboxChange(e)}
              />
              <label for='addToList'>&nbsp; Keep Me Updated</label>
            </div>
            <input type='submit' className='btn btn-primary' value='Register' />
          </form>
          <p className='my-1'>
            Already have an account? <Link to='/login'>Sign In</Link>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

Register.propTypes = {
  register: PropTypes.func.isRequired,
  clearAuthErrors: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  error: PropTypes.string,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.auth.errors?.errors[0]?.msg,
  loginMessage: state.auth.loginMessage,
});

export default connect(mapStateToProps, {
  register,
  clearAuthErrors,
  passwordMismatch,
})(Register);
