import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  FORGOT_PW_SUCCESS,
  FORGOT_PW_REDIRECT,
  LOGOUT,
  USER_LOADED,
  AUTH_ERROR,
  RESET_PW_SUCCESS,
  RESET_PW_FAIL,
  CLEAR_AUTH_ERRORS,
  PASSWORD_MISMATCH,
  MATE_IN_3_TEST_COMPLETE,
} from '../actions/types';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  isPremium: true,
  resetPassword: null,
  loading: true,
  user: null,
  errors: null,
  loginMessage: null,
  resetPasswordSuccess: false,
};

export default function reduce(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isPremium: true, // change to payload.isPremium for gating
        loading: false,
        user: payload,
      };
    case RESET_PW_SUCCESS:
      return {
        ...state,
        errors: null,
        resetPasswordSuccess: true,
      };
    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS:
      localStorage.setItem('token', payload.token);
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
      };
    case FORGOT_PW_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        resetPassword: true,
        loading: false,
      };
    case FORGOT_PW_REDIRECT: {
      return {
        ...state,
        resetPassword: false,
      };
    }
    case RESET_PW_FAIL: {
      console.log('payload', payload);
      return {
        ...state,
        passwordIsReset: false,
        errors: payload,
      };
    }
    case LOGOUT:
    case LOGIN_FAIL:
    case AUTH_ERROR:
    case REGISTER_FAIL:
      localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        errors: payload,
        user: null,
      };
    case CLEAR_AUTH_ERRORS:
      return {
        ...state,
        errors: null,
        loginMessage: null,
      };
    case PASSWORD_MISMATCH:
      // I'm sure there's a better way to do this crap
      const errors = [];
      errors[0] = { msg: 'Passwords do not match' };
      const errorsArr = [];
      errorsArr['errors'] = errors;
      return {
        ...state,
        errors: errorsArr,
      };
    case MATE_IN_3_TEST_COMPLETE:
      return {
        ...state,
        loginMessage: 'Great job! Register to get more problems!',
      };
    default:
      return state;
  }
}
