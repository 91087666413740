import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import './css/navbar.css';
import { logout } from '../../../actions/auth';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const register = (
  <li>
    <Link to='/login'>
      <Button variant='primary' size='sm' className='navbar-button'>
        Log In/Register
      </Button>
    </Link>
  </li>
);

const Navbar = ({ auth: { isAuthenticated, loading }, logout }) => {
  const guestLinks = (
    <ul>
      <li>
        <Link to='/help'>
          <i className='fas fa-code'></i> Help
        </Link>
      </li>
      <li>
        <Link to='/mate-in-3-test'>Mate-in-3 Test</Link>
      </li>
      {register}
    </ul>
  );

  const smallGuestLinks = <ul>{register}</ul>;

  const authLinks = (
    <ul>
      <li>
        <Link to='/help'>
          <i className='fas fa-code'></i> Help
        </Link>
        <Link to='/' onClick={logout}>
          <i className='fas fa-code'></i> Log out
        </Link>
      </li>
    </ul>
  );

  const width = useWindowSize().width;

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowSize;
  }

  const guestLinksToShow = width < 660 ? smallGuestLinks : guestLinks;

  return (
    <div className='navbar-container'>
      <h1 className='float-left'>
        <Link to='/'>
          <i className='fas fa-code'></i> ChessGoals(Beta)
        </Link>
      </h1>
      <div className='navbar-links float-right'>
        {!loading && <>{isAuthenticated ? authLinks : guestLinksToShow}</>}
      </div>
    </div>
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(Navbar);
