import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './css/explain.css';

const Explain = () => (
  <div className='explain-container'>
    <Container>
      <Row className='justify-content-center'>
        <Col xs={7}>
          <p>
            At ChessGoals we know you want to boost your chess rating. To
            improve you need to spend hours studying, playing, and analyzing.
            The problem is knowing how and what to study, leaving us overwhelmed
            and frustrated.
          </p>
          <p>
            Other standard tactics trainers will give you a position and find
            the best move. This helps improve tactical sharpness, but doesn't
            help you find these tactics in a real game. Playing sharp positions
            in games takes much more calculation and you have to be prepared to
            play against difficult human lines, not just the top computer move.
          </p>
          <p>
            This is why we created ChessGoals. We want to train tactics to boost
            our real rating, not just our tactics ranking. That's why each
            problem is hand-selected from real games. That's why each tactic
            problem covers many typical responses, including the engine's top
            recommendation and the difficult continuations that humans would
            play.
          </p>
          <div className='text-center'>
            <Link to='/mate-in-3-test'>
              <Button variant='primary' size='lg' className='mate-test-btn'>
                Try the Mate-In-3 Test
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Explain;
