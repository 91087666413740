import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { forgotPassword, passwordRedirect } from '../../actions/auth.js';

const ForgotPassword = ({
  forgotPassword,
  passwordReset,
  passwordRedirect,
}) => {
  const [formData, setFormData] = useState({
    email: '',
  });

  const { email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    forgotPassword(email);
  };

  if (passwordReset) {
    passwordRedirect();
    return <Redirect to='/' />;
  }

  return (
    <Container>
      <Row className='justify-content-center'>
        <Col xs={6} className='text-center mt-5'>
          <p className='mt-1'>
            Forgot your password? Enter your e-mail and we'll send you a link.
          </p>
          <form className='form mt-3' onSubmit={(e) => onSubmit(e)}>
            <div className='form-group'>
              <input
                type='email'
                placeholder='Email Address'
                name='email'
                value={email}
                size={40}
                onChange={(e) => onChange(e)}
              />
            </div>
            <input type='submit' className='btn btn-primary' value='Reset' />
          </form>
          <p className='mt-3'>
            Don't have an account? <Link to='/register'>Register</Link>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

ForgotPassword.propTypes = {
  forgotPassword: PropTypes.func.isRequired,
  passwordRedirect: PropTypes.func.isRequired,
  passwordReset: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  passwordReset: state.auth.resetPassword,
});

export default connect(mapStateToProps, { forgotPassword, passwordRedirect })(
  ForgotPassword
);
