import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Board from './Board';
import Check from './assets/check.svg';
import Square from './assets/square.svg';
import X from './assets/x.svg';
import Warning from './assets/warning.svg';
import { Button } from 'react-bootstrap';
import { problemCompleted } from '../../actions/completedProblems';

const style = {
  textAlign: 'center',
  position: 'relative',
  paddingTop: '24px',
  marginBottom: '48px',
};

const button = {
  marginTop: '12px',
  marginBottom: '24px',
};

class ProblemItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numCompleted: 0,
      wrongVisible: false,
      rightVisible: false,
      altVisible: false,
      solved: false,
    };
    this.onClick = this.advance.bind(this);
  }

  advance() {
    this.props.onNext();
  }

  squares() {
    const squares = this.props.lines.map((line, index) => {
      return this.state.numCompleted > index ? (
        <img src={Check} alt="completed" />
      ) : (
        <img src={Square} alt="not completed" />
      );
    });

    return squares;
  }

  lineCompleted = () => {
    if (this.state.numCompleted + 1 === this.props.lines.length) {
      this.setState({ solved: true });

      this.props.problemCompleted(this.props.problemId);
    }
    this.setState({ numCompleted: this.state.numCompleted + 1 });
  };

  wrongAnswer = () => {
    this.setState({ wrongVisible: true, rightVisible: false });

    setTimeout(() => {
      this.setState({
        wrongVisible: false,
        rightVisible: false,
        altVisible: false,
      });
    }, 800);
  };

  rightAnswer = () => {
    this.setState({ rightVisible: true, wrongVisible: false });

    setTimeout(() => {
      this.setState({
        wrongVisible: false,
        rightVisible: false,
        altVisible: false,
      });
    }, 800);
  };

  altAnswer = () => {
    this.setState({
      rightVisible: false,
      wrongVisible: false,
      altVisible: true,
    });

    setTimeout(() => {
      this.setState({
        wrongVisible: false,
        rightVisible: false,
        altVisible: false,
      });
    }, 800);
  };

  redX() {
    if (this.state.wrongVisible) {
      return <img src={X} alt="wrong" />;
    }

    if (this.state.rightVisible) {
      return <img src={Check} alt="correct" />;
    }

    if (this.state.altVisible) {
      return <img src={Warning} alt="alternate move" />;
    }

    const style = {
      width: '24px',
    };
    return <div style={style}>{}</div>;
  }

  board() {
    return (
      <Board
        fen={this.props.fen}
        lines={this.props.lines.slice()}
        lineCompleted={this.lineCompleted}
        wrongAnswer={this.wrongAnswer}
        rightAnswer={this.rightAnswer}
        alternateAnswer={this.altAnswer}
      />
    );
  }

  render() {
    return (
      <div style={style}>
        <h2>
          Problem ID: {this.props.problemId} {this.redX()}
        </h2>
        <h4>
          {this.props.fen.includes('w') ? 'Black to play' : 'White to play'}
        </h4>
        <div>{this.squares()}</div>
        <div>
          <Button
            variant="primary"
            size="sm"
            style={button}
            onClick={(e) => this.advance()}
          >
            Next
          </Button>
        </div>
        <div>{this.board()}</div>
      </div>
    );
  }
}

ProblemItem.propTypes = {
  index: PropTypes.number.isRequired,
  fen: PropTypes.string.isRequired,
  lines: PropTypes.array.isRequired,
  problemId: PropTypes.string.isRequired,
  problemCompleted: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
};
export default connect(null, { problemCompleted })(ProblemItem);
