export function moveResult(history, lines) {
  let result = 'incorrect';
  lines.forEach((lineString) => {
    const line = lineString.split(' ');
    for (let index = 0; index < history.length; index++) {
      if (history[index] !== line[index]) {
        if (isAlternateMove(history, line, index)) {
          result = 'alt';
        }
        break;
      }
      if (index === history.length - 1) {
        result = 'correct';
      }
    }
  });

  return result;
}

function isAlternateMove(history, line, index) {
  if (line.length <= index + 1) {
    return false;
  }

  const move = line[index + 1];
  const altMove = move.substring(1, move.length - 1);
  return history[index] === altMove;
}

export function getFirstMove(lines) {
  return lines[0].split(' ')[0];
}

export function getNextMove(history, lines, moveNum) {
  const activeLine = getActiveLine(history, lines);
  return activeLine[moveNum];
}

function getActiveLine(history, lines) {
  let activeLine = null;
  lines.forEach((lineString) => {
    const line = lineString.trim().split(' ');
    for (let index = 0; index < history.length; index++) {
      if (history[index] !== line[index]) {
        continue;
      }
      if (index === history.length - 1) {
        activeLine = line;
        break;
      }
    }
  });
  return activeLine;
}

export function getCompletedLine(history, lines) {
  let result = null;
  lines.forEach((lineString) => {
    const line = lineString.trim().split(' ');
    for (let index = 0; index < history.length; index++) {
      if (history[index] !== line[index]) {
        continue;
      }
      if (index === history.length - 1 && history.length === line.length) {
        result = lineString;
        break;
      }
    }
  });
  return result;
}

export function getCompletedAltCheckmateLine(history, lines) {
  let result = null;
  lines.forEach((lineString) => {
    const line = lineString.trim().split(' ');
    for (let index = 0; index < history.length - 1; index++) {
      if (history[index] !== line[index]) {
        continue;
      }
      if (index === history.length - 2 && history.length === line.length) {
        result = lineString;
        break;
      }
    }
  });
  return result;
}

export function removeNextAltMove(history, lines, index) {
  const nextMove = getNextMove(history, lines, index);
  if (nextMove && nextMove[0] === '[') {
    const line = getActiveLineIndex(history, lines);
    const newLine = lines[line].replace(` ${nextMove}`, '');
    lines[line] = newLine;
  }
  return lines;
}

function getActiveLineIndex(history, lines) {
  let activeLine = -1;
  lines.forEach((lineString, lineIndex) => {
    const line = lineString.split(' ');
    for (let index = 0; index < history.length; index++) {
      if (history[index] !== line[index]) {
        continue;
      }
      if (index === history.length - 1) {
        activeLine = lineIndex;
        break;
      }
    }
  });
  return activeLine;
}
