import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';

const MixpanelInitializer = ({ userId }) => {
  useEffect(() => {
    if (userId) {
      mixpanel.identify(userId);
    } else {
      mixpanel.identify(v4());
    }
  }, userId);

  return null;
};

MixpanelInitializer.propTypes = {
  userId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  userId: state.auth?.user?._id,
});

export default connect(mapStateToProps)(MixpanelInitializer);
