export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const FORGOT_PW_SUCCESS = 'FORGOT_PW_SUCCESS';
export const FORGOT_PW_FAIL = 'FORGOT_PW_FAIL';
export const FORGOT_PW_REDIRECT = 'FORGOT_PW_REDIRECT';
export const RESET_PW_SUCCESS = 'RESET_PW_SUCCESS';
export const RESET_PW_FAIL = 'RESET_PW_FAIL';
export const LOGOUT = 'LOGOUT';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const CLEAR_AUTH_ERRORS = 'CLEAR_AUTH_ERRORS';
export const PASSWORD_MISMATCH = 'PASSWORD_MISMATCH';
export const MATE_IN_3_TEST_COMPLETE = 'MATE_IN_3_TEST_COMPLETE';

export const GET_MATE_IN_1 = 'GET_MATE_IN_1';
export const MATE_IN_1_SUCCESS = 'MATE_IN_1_SUCCESS';
export const MATE_IN_1_FAILURE = 'MATE_IN_1_FAILURE';
export const GET_MATE_IN_2 = 'GET_MATE_IN_2';
export const MATE_IN_2_SUCCESS = 'MATE_IN_2_SUCCESS';
export const MATE_IN_2_FAILURE = 'MATE_IN_2_FAILURE';
export const GET_MATE_IN_3 = 'GET_MATE_IN_3';
export const MATE_IN_3_SUCCESS = 'MATE_IN_3_SUCCESS';
export const MATE_IN_3_FAILURE = 'MATE_IN_3_FAILURE';
export const GET_MATE_IN_3_TEST = 'GET_MATE_IN_3_TEST';
export const MATE_IN_3_TEST_SUCCESS = 'MATE_IN_3_TEST_SUCCESS';
export const MATE_IN_3_TEST_FAILURE = 'MATE_IN_3_TEST_FAILURE';
export const GET_MATE_IN_4 = 'GET_MATE_IN_4';
export const MATE_IN_4_SUCCESS = 'MATE_IN_4_SUCCESS';
export const MATE_IN_4_FAILURE = 'MATE_IN_4_FAILURE';
export const GET_MATE_IN_MORE = 'GET_MATE_IN_MORE';
export const MATE_IN_MORE_SUCCESS = 'MATE_IN_MORE_SUCCESS';
export const MATE_IN_MORE_FAILURE = 'MATE_IN_MORE_FAILURE';
export const GET_FORKS = 'GET_FORKS';
export const GET_FORKS_SUCCESS = 'GET_FORKS_SUCCESS';
export const GET_FORKS_FAILURE = 'GET_FORKS_FAILURE';
export const GET_PINS = 'GET_PINS';
export const GET_PINS_SUCCESS = 'GET_PINS_SUCCESS';
export const GET_PINS_FAILURE = 'GET_PINS_FAILURE';
export const GET_DISCOVERIES = 'GET_DISCOVERIES';
export const GET_DISCOVERIES_SUCCESS = 'GET_DISCOVERIES_SUCCESS';
export const GET_DISCOVERIES_FAILURE = 'GET_DISCOVERIES_FAILURE';
export const GET_SACRIFICES = 'GET_SACRIFICES';
export const GET_SACRIFICES_SUCCESS = 'GET_SACRIFICES_SUCCESS';
export const GET_SACRIFICES_FAILURE = 'GET_SACRIFICES_FAILURE';

export const GET_PROBLEMS_SUCCESS = 'GET_PROBLEMS_SUCCESS';
export const GET_PROBLEMS_FAILURE = 'GET_PROBLEMS_FAILURE';
export const PROBLEM_COMPLETE_SUCCESS = 'PROBLEM_COMPLETE_SUCCESS';
export const PROBLEM_COMPLETE_FAILURE = 'PROBLEM_COMPLETE_FAILURE';

export const GET_EVALS = 'GET_EVALS'
export const GET_EVALS_SUCCESS = 'GET_EVALS_SUCCESS'
export const GET_EVALS_FAILURE = 'GET_EVALS_FAILURE'

export const SOLVE_MODE_REVIEW = 'SOLVE_MODE_REVIEW';
export const SOLVE_MODE_LEARN = 'SOLVE_MODE_LEARN';
