import { SOLVE_MODE_LEARN, SOLVE_MODE_REVIEW } from '../actions/types';

const initialState = {
  solveMode: null,
};

export default function reduce(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case SOLVE_MODE_LEARN:
    case SOLVE_MODE_REVIEW:
      return {
        ...state,
        solveMode: type,
      };
    default:
      return state;
  }
}
