import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import ProblemCard from './ProblemCard';
import { getMateIn1 } from '../../actions/problems';
import { getMateIn2 } from '../../actions/problems';
import { getMateIn3 } from '../../actions/problems';
import { getMateIn4 } from '../../actions/problems';
import { getMateInMore } from '../../actions/problems';
import { getPins } from '../../actions/problems';
import { getForks } from '../../actions/problems';
import { getDiscoveries } from '../../actions/problems';
import { getSacrifices } from '../../actions/problems';
import { getEvals } from '../../actions/problems';
import { getProblems } from '../../actions/completedProblems';

const Dashboard = ({
  getMateIn1,
  getMateIn2,
  getMateIn3,
  getMateIn4,
  getMateInMore,
  getProblems,
  getPins,
  getForks,
  getDiscoveries,
  getSacrifices,
  getEvals,
  problems: {
    mateIn1Problems,
    mateIn2Problems,
    mateIn3Problems,
    mateIn4Problems,
    mateInMoreProblems,
    pinProblems,
    forkProblems,
    discoveryProblems,
    sacrificeProblems,
    evalProblems,
  },
  completedProblems: { completedProblems },
}) => {
  useEffect(() => {
    getMateIn1();
    getMateIn2();
    getMateIn3();
    getMateIn4();
    getMateInMore();
    getProblems();
    getForks();
    getPins();
    getDiscoveries();
    getSacrifices();
    getEvals();
  }, [
    getMateIn1,
    getMateIn2,
    getMateIn3,
    getMateIn4,
    getMateInMore,
    getProblems,
    getPins,
    getForks,
    getDiscoveries,
    getSacrifices,
    getEvals,
  ]);

  return (
    <div>
      <Container>
        <Row className='justify-content-center'>
          <Col lg={4}>
            <ProblemCard
              title='Guess The Eval'
              path='/guesstheeval'
              problems={evalProblems}
              completed={completedProblems}
            />
          </Col>
          <Col lg={4}>
            <ProblemCard
              title='Mate In 1'
              path='/mate-in-1'
              problems={mateIn1Problems}
              completed={completedProblems}
            />
          </Col>
          <Col lg={4}>
            <ProblemCard
              title='Mate In 2'
              path='/mate-in-2'
              problems={mateIn2Problems}
              completed={completedProblems}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <ProblemCard
              title='Mate In 3'
              path='/mate-in-3'
              problems={mateIn3Problems}
              completed={completedProblems}
            />
          </Col>
          <Col lg={4}>
            <ProblemCard
              title='Mate In 4'
              path='/mate-in-4'
              problems={mateIn4Problems}
              completed={completedProblems}
            />
          </Col>
          <Col lg={4}>
            <ProblemCard
              title='Mate In More'
              path='/mate-in-more'
              problems={mateInMoreProblems}
              completed={completedProblems}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <ProblemCard
              title='Forks'
              path='/forks'
              problems={forkProblems}
              completed={completedProblems}
            />
          </Col>
          <Col lg={4}>
            <ProblemCard
              title='Pins'
              path='/pins'
              problems={pinProblems}
              completed={completedProblems}
            />
          </Col>
          <Col lg={4}>
            <ProblemCard
              title='Discoveries'
              path='/discoveries'
              problems={discoveryProblems}
              completed={completedProblems}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <ProblemCard
              title='Sacrifices'
              path='/sacrifices'
              problems={sacrificeProblems}
              completed={completedProblems}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Dashboard.propTypes = {
  getMateIn1: PropTypes.func.isRequired,
  getMateIn2: PropTypes.func.isRequired,
  getMateIn3: PropTypes.func.isRequired,
  getMateIn4: PropTypes.func.isRequired,
  getMateInMore: PropTypes.func.isRequired,
  getProblems: PropTypes.func.isRequired,
  getPins: PropTypes.func.isRequired,
  getForks: PropTypes.func.isRequired,
  getDiscoveries: PropTypes.func.isRequired,
  getSacrifices: PropTypes.func.isRequired,
  getEvals: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  problems: state.problems,
  completedProblems: state.completedProblems,
});

export default connect(mapStateToProps, {
  getMateIn1,
  getMateIn2,
  getMateIn3,
  getMateIn4,
  getMateInMore,
  getProblems,
  getPins,
  getForks,
  getDiscoveries,
  getSacrifices,
  getEvals,
})(Dashboard);
