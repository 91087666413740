import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { resetPassword, passwordMismatch } from '../../actions/auth.js';

const ResetPassword = ({
  resetPassword,
  passwordMismatch,
  match,
  resetPasswordSuccess,
  error,
}) => {
  const [formData, setFormData] = useState({
    email: '',
  });

  const { email, password, password2 } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== password2) {
      passwordMismatch();
    } else {
      resetPassword(email, password, match.params.token);
    }
  };

  if (resetPasswordSuccess) {
    return <Redirect to='/login' />;
  }

  const errorDiv =
    error && error.length > 0 ? (
      <div class='alert alert-danger' role='alert'>
        {error}
      </div>
    ) : (
      <></>
    );

  return (
    <Container>
      <Row className='justify-content-center'>
        <Col xs={6} className='text-center'>
          <h1 className='large text-primary mt-5'>Reset Password</h1>
          {errorDiv}
          <form className='form' onSubmit={(e) => onSubmit(e)}>
            <div className='form-group'>
              <input
                type='email'
                placeholder='Email Address'
                name='email'
                value={email}
                size={40}
                onChange={(e) => onChange(e)}
              />
            </div>
            <div className='form-group'>
              <input
                type='password'
                placeholder='New Password'
                name='password'
                value={password}
                size={40}
                onChange={(e) => onChange(e)}
              />
            </div>
            <div className='form-group'>
              <input
                type='password'
                placeholder='Confirm Password'
                value={password2}
                size={40}
                onChange={(e) => onChange(e)}
                name='password2'
              />
            </div>
            <input type='submit' className='btn btn-primary' value='Reset' />
          </form>
          <p className='my-1'>
            Don't have an account? <Link to='/register'>Register</Link>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

ResetPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  passwordReset: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  passwordReset: state.auth.resetPassword,
  isAuthenticated: state.auth.isAuthenticated,
  resetPasswordSuccess: state.auth.resetPasswordSuccess,
  error: state.auth.errors?.errors[0]?.msg,
});

export default connect(mapStateToProps, { resetPassword, passwordMismatch })(
  ResetPassword
);
