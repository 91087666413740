import axios from 'axios';
import {
  GET_MATE_IN_1,
  MATE_IN_1_SUCCESS,
  MATE_IN_1_FAILURE,
  GET_MATE_IN_2,
  MATE_IN_2_SUCCESS,
  MATE_IN_2_FAILURE,
  GET_MATE_IN_3,
  MATE_IN_3_SUCCESS,
  MATE_IN_3_FAILURE,
  GET_MATE_IN_3_TEST,
  MATE_IN_3_TEST_SUCCESS,
  MATE_IN_3_TEST_FAILURE,
  GET_MATE_IN_4,
  MATE_IN_4_SUCCESS,
  MATE_IN_4_FAILURE,
  GET_MATE_IN_MORE,
  MATE_IN_MORE_SUCCESS,
  MATE_IN_MORE_FAILURE,
  GET_FORKS_SUCCESS,
  GET_FORKS_FAILURE,
  GET_FORKS,
  GET_PINS,
  GET_PINS_SUCCESS,
  GET_PINS_FAILURE,
  GET_DISCOVERIES,
  GET_DISCOVERIES_SUCCESS,
  GET_DISCOVERIES_FAILURE,
  GET_SACRIFICES,
  GET_SACRIFICES_SUCCESS,
  GET_SACRIFICES_FAILURE,
  GET_EVALS,
  GET_EVALS_SUCCESS,
  GET_EVALS_FAILURE,
} from '../actions/types';

export const getMateIn1 = () => async (dispatch) => {
  dispatch({ type: GET_MATE_IN_1 });
  try {
    const res = await axios.get('/api/problems/mate-in-1');
    dispatch({
      type: MATE_IN_1_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: MATE_IN_1_FAILURE,
      payload: { msg: err.response, status: err.response.status },
    });
  }
};

export const getMateIn2 = () => async (dispatch) => {
  dispatch({ type: GET_MATE_IN_2 });
  try {
    const res = await axios.get('/api/problems/mate-in-2');
    dispatch({
      type: MATE_IN_2_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: MATE_IN_2_FAILURE,
      payload: { msg: err.response, status: err.response.status },
    });
  }
};

export const getMateIn3 = () => async (dispatch) => {
  dispatch({ type: GET_MATE_IN_3 });
  try {
    const res = await axios.get('/api/problems/mate-in-3');
    dispatch({
      type: MATE_IN_3_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: MATE_IN_3_FAILURE,
      payload: { msg: err.response, status: err.response.status },
    });
  }
};

export const getMateIn3Test = () => async (dispatch) => {
  dispatch({ type: GET_MATE_IN_3_TEST });
  try {
    const res = await axios.get('/api/problems/mate-in-3-test');
    dispatch({
      type: MATE_IN_3_TEST_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: MATE_IN_3_TEST_FAILURE,
      payload: { msg: err.response, status: err.response.status },
    });
  }
};

export const getMateIn4 = () => async (dispatch) => {
  dispatch({ type: GET_MATE_IN_4 });
  try {
    const res = await axios.get('/api/problems/mate-in-4');
    dispatch({
      type: MATE_IN_4_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: MATE_IN_4_FAILURE,
      payload: { msg: err.response, status: err.response.status },
    });
  }
};

export const getMateInMore = () => async (dispatch) => {
  dispatch({ type: GET_MATE_IN_MORE });
  try {
    const res = await axios.get('/api/problems/mate-in-more');
    dispatch({
      type: MATE_IN_MORE_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: MATE_IN_MORE_FAILURE,
      payload: { msg: err.response, status: err.response.status },
    });
  }
};

export const getForks = () => async (dispatch) => {
  dispatch({ type: GET_FORKS });
  try {
    const res = await axios.get('/api/problems/forks');
    dispatch({
      type: GET_FORKS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_FORKS_FAILURE,
      payload: { msg: err.response, status: err.response.status },
    });
  }
};

export const getPins = () => async (dispatch) => {
  dispatch({ type: GET_PINS });
  try {
    const res = await axios.get('/api/problems/pins');
    dispatch({
      type: GET_PINS_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_PINS_FAILURE,
      payload: { msg: err.response, status: err.response.status },
    });
  }
};

export const getDiscoveries = () => async (dispatch) => {
  dispatch({ type: GET_DISCOVERIES });
  try {
    const res = await axios.get('/api/problems/discoveries');
    dispatch({
      type: GET_DISCOVERIES_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_DISCOVERIES_FAILURE,
      payload: { msg: err.response, status: err.response.status },
    });
  }
};

export const getSacrifices = () => async (dispatch) => {
  dispatch({ type: GET_SACRIFICES });
  try {
    const res = await axios.get('/api/problems/sacrifices');
    dispatch({
      type: GET_SACRIFICES_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_SACRIFICES_FAILURE,
      payload: { msg: err.response, status: err.response.status },
    });
  }
};

export const getEvals = () => async (dispatch) => {
  dispatch({ type: GET_EVALS});
  try {
    const res = await axios.get('/api/eval')
    dispatch({
      type: GET_EVALS_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GET_EVALS_FAILURE,
      payload: { msg: err.response, status: err.response.status },
    });
  }
}
