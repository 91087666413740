import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProblemItem from '../../layouts/ProblemItem';
import { useHistory } from 'react-router-dom';
import { mateIn3TestComplete } from '../../../actions/auth';
import { getMateIn3Test } from '../../../actions/problems';

const MateInThreeTest = ({
  getMateIn3Test,
  problems: { mateIn3TestProblems, loading },
  mateIn3TestComplete,
}) => {
  const history = useHistory();
  const [index, setCount] = useState(0);

  const updateIndex = () => {
    setCount(index + 1);
  };

  useEffect(() => {
    getMateIn3Test();
  }, [getMateIn3Test]);

  let problemsToSolve = null;
  if (mateIn3TestProblems) {
    problemsToSolve = mateIn3TestProblems;
  }

  if (problemsToSolve && index >= problemsToSolve.length) {
    mateIn3TestComplete();
    history.push('/register');
    return <div></div>;
  }

  return (
    <React.Fragment>
      {loading ? (
        <div>...</div>
      ) : (
        <ProblemItem
          key={problemsToSolve[index].fen}
          index={index}
          fen={problemsToSolve[index].fen}
          lines={problemsToSolve[index].lines}
          problemId={problemsToSolve[index].puzzleId}
          onNext={updateIndex}
        />
      )}
    </React.Fragment>
  );
};

MateInThreeTest.propTypes = {
  getMateIn3Test: PropTypes.func.isRequired,
  problems: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  problems: state.problems,
});
export default connect(mapStateToProps, {
  getMateIn3Test,
  mateIn3TestComplete,
})(MateInThreeTest);
