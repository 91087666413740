import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import MixpanelInitializer from './components/Mixpanel/MixpanelInitializer';
import Home from './components/Home/Home';
import MateInOne from './components/Problems/MateInOne/MateInOne';
import MateInTwo from './components/Problems/MateInTwo/MateInTwo';
import MateInThree from './components/Problems/MateInThree/MateInThree';
import MateInThreeTest from './components/Problems/MateInThreeTest/MateInThreeTest';
import MateInFour from './components/Problems/MateInFour/MateInFour';
import MateInMore from './components/Problems/MateInMore/MateInMore';
import Pins from './components/Problems/Pins/Pins';
import Forks from './components/Problems/Forks/Forks';
import Discoveries from './components/Problems/Discoveries/Discoveries';
import Sacrifices from './components/Problems/Sacrifices/Sacrifices';
import Navbar from './components/layouts/Navbar/Navbar';
import Register from './components/Register/Register';
import Login from './components/Login/Login';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import ResetPassword from './components/ResetPassword/ResetPassword';
import Dashboard from './components/Dashboard/Dashboard';
import Premium from './components/Premium/Premium';
import GuessTheEval from './components/GuessTheEval/GuessTheEval';
import Help from './components/Help/Help';
import PrivateRoute from './components/Routing/PrivateRoute';
import './font.css';

import { Provider } from 'react-redux';
import store from './store';
import setAuthToken from './utils/setAuthToken';
import { loadUser } from './actions/auth';
import { TestBoard } from './components/Board/TestBoard';
import mixpanel from 'mixpanel-browser';

const debug = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
mixpanel.init('404428cab0e95fc1e162b580f26560ca', {
  debug: debug,
  track_pageview: true,
  persistence: 'localStorage',
});

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <MixpanelInitializer />
      <Router>
        <Fragment>
          <Navbar />
          <Route exact path='/' component={Home} />
          <Switch>
            <Route exact path='/register' component={Register} />
            <Route exact path='/login' component={Login} />
            <Route exact path='/forgot-password' component={ForgotPassword} />
            <Route
              exact
              path='/reset-password/:token'
              component={ResetPassword}
            />
            <Route exact path='/mate-in-3-test' component={MateInThreeTest} />
            <PrivateRoute exact path='/dashboard' component={Dashboard} />
            <Route exact path='/testboard' component={TestBoard} />
            <PrivateRoute exact path='/mate-in-1' component={MateInOne} />
            <PrivateRoute exact path='/mate-in-2' component={MateInTwo} />
            <PrivateRoute exact path='/mate-in-3' component={MateInThree} />
            <PrivateRoute exact path='/mate-in-4' component={MateInFour} />
            <PrivateRoute exact path='/mate-in-more' component={MateInMore} />
            <PrivateRoute exact path='/pins' component={Pins} />
            <PrivateRoute exact path='/forks' component={Forks} />
            <PrivateRoute exact path='/discoveries' component={Discoveries} />
            <PrivateRoute exact path='/sacrifices' component={Sacrifices} />
            <PrivateRoute exact path='/premium' component={Premium} />
            <PrivateRoute exact path='/guesstheeval' component={GuessTheEval} />
            <Route exact path='/help' component={Help} />
          </Switch>
        </Fragment>
      </Router>
    </Provider>
  );
};

export default App;
