import React from 'react';
import { Link } from 'react-router-dom';
import { Jumbotron, Button } from 'react-bootstrap';
import './css/header.css';

const Header = () => (
  <Jumbotron fluid className='jumbotron-header'>
    <div className='content-container'>
      <h1 className='text-center header-text'>Welcome to ChessGoals</h1>
      <div className='text-center'>
        <Link to='/mate-in-3-test'>
          <Button variant='primary' className='mate-test-btn'>
            Try the Mate-In-3 Test
          </Button>
        </Link>
      </div>
    </div>
  </Jumbotron>
);

export default Header;
