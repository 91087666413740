import {
  SOLVE_MODE_LEARN,
  SOLVE_MODE_REVIEW
} from "../actions/types";

export const markSolveModeLearn = () => async dispatch => {
  dispatch({ type: SOLVE_MODE_LEARN })
}

export const markSolveModeReview = () => async dispatch => {
  dispatch({ type: SOLVE_MODE_REVIEW })
}
