import axios from 'axios';
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  FORGOT_PW_SUCCESS,
  FORGOT_PW_FAIL,
  FORGOT_PW_REDIRECT,
  RESET_PW_SUCCESS,
  RESET_PW_FAIL,
  LOGOUT,
  USER_LOADED,
  AUTH_ERROR,
  CLEAR_AUTH_ERRORS,
  PASSWORD_MISMATCH,
  MATE_IN_3_TEST_COMPLETE,
} from './types';
import setAuthToken from '../utils/setAuthToken';

// Load User
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get('/api/auth');

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Register User
export const register = ({ name, email, password, addToList }) => async (
  dispatch
) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const lowerEmail = email.toLowerCase();
  const body = JSON.stringify({ name, email: lowerEmail, password, addToList });

  try {
    const res = await axios.post('/api/users', body, config);

    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });

    dispatch(loadUser());
  } catch (err) {
    console.error(err);
    dispatch({
      type: REGISTER_FAIL,
      payload: err.response.data,
    });
  }
};

// Login User
export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const lowerEmail = email.toLowerCase();
  const body = JSON.stringify({ email: lowerEmail, password });
  try {
    const res = await axios.post('/api/auth', body, config);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    dispatch(loadUser());
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
      payload: err.response.data,
    });
  }
};

export const clearAuthErrors = () => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR_AUTH_ERRORS,
    });
  } catch (err) {}
};

export const passwordMismatch = () => async (dispatch) => {
  try {
    dispatch({
      type: PASSWORD_MISMATCH,
    });
  } catch (err) {}
};

export const forgotPassword = (email) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const lowerEmail = email.toLowerCase();
  const body = JSON.stringify({ email: lowerEmail });
  try {
    const res = await axios.post('/api/auth/forgot-password', body, config);

    dispatch({
      type: FORGOT_PW_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: FORGOT_PW_FAIL,
    });
  }
};

export const passwordRedirect = () => async (dispatch) => {
  dispatch({
    type: FORGOT_PW_REDIRECT,
    payload: '',
  });
};

export const resetPassword = (email, password, resetToken) => async (
  dispatch
) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const lowerEmail = email.toLowerCase();
  const body = JSON.stringify({
    email: lowerEmail,
    password,
    token: resetToken,
  });
  try {
    const res = await axios.post('/api/auth/reset-password', body, config);

    dispatch({
      payload: res.data,
      type: RESET_PW_SUCCESS,
    });
  } catch (err) {
    console.log('err', err.response.data);
    dispatch({
      payload: err.response.data,
      type: RESET_PW_FAIL,
    });
  }
};

// Logout / Clear Profile

export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
};

export const mateIn3TestComplete = () => (dispatch) => {
  dispatch({ type: MATE_IN_3_TEST_COMPLETE });
};
