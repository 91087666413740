import React from 'react';
import Board from '../layouts/Board';

// const fen = '2rr2k1/1p1q1b2/p1n2pp1/P1Qp3p/2pP3P/2P1N1P1/2P1RPBK/4R3 w - - 1 1';
// const lines = [
//   'Nxd5 Bxd5 Bxd5+ Qxd5 Re8+ Kh7 Qxd5 Rxd5 Rxc8',
//   'Nxd5 Bxd5 Bxd5+ Kh8 Be6 Nxd4 Qxd4',
//   'Nxd5 Qf5 Be4 Rxd5 Bxf5 Rxc5 Bxc8'
// ];

// const fen = '6rk/pp2pp1p/3p2pQ/2qP2Pn/5r2/1PP3N1/P7/1K1R3R w - - 1 1';
// const lines = ['Nxh5 gxh5 g6 fxg6 Qxf4', 'Nxh5 gxh5 g6 Qxc3 Qxh7#', 'Nxh5 gxh5 g6 Rxg6 Qxf4', 'Nxh5 Rh4 Rxh4'];

const fen = '2qrb1k1/1p2bppp/1n2p3/p3P3/3NN3/1P4Q1/PB3PPP/3R2K1 b - - 0 1';
const lines = [
  'Qc2 Nf6+ Kh8 [Bxf6] Qd3 Qxd3 Rxd3 gxf6',
  'Qc2 Nc3 Qxb2',
  'Qc2 Qc3 Qxd1+ Qe1 Qxe1#',
  'Qc2 Nf6+ Kh8 Qd3 Qxd3 Rxd3 gxf6',
  'Qc2 Nf6+ Kh8 Qf3 Qxb2',
  'Qc2 Nxc2 Rxd1+ Ne1 Rxe1#'
];

export const TestBoard = () => {
  return (
    <div>
      <Board fen={fen} lines={lines} />
    </div>
  );
};
